/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
 Authenticator, Greetings, SignUp, SignIn,
} from 'aws-amplify-react';
import { loggedIn } from '../store/actions/authActions';
import CustomSignInForm from '../components/CustomSignInForm';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleStateChange = this.handleStateChange.bind(this);
  }

  handleStateChange(state) {
    if (state === 'signedIn') {
      this.props.loggedIn();
    }
  }

  render() {
    if (this.props.auth.currentUser) {
      return <Redirect to="/product-owner" />;
    }
    return (
      <Row className="justify-content-center mt-5">
        <Col xl={6}>
          <h2>Login to Your Account</h2>
          <Authenticator
            hide={[Greetings, SignUp, SignIn]}
            onStateChange={this.handleStateChange}
          >
            <CustomSignInForm />
          </Authenticator>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  loggedIn: () => dispatch(loggedIn()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
