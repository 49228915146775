import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Scrollchor from 'react-scrollchor';
import MetricWidget from '../components/MetricWidget';
import SubscriptionPlanOverview from '../components/SubscriptionPlanOverview';
import Sidebar from '../components/Sidebar';
import TitleRow from '../components/TitleRow';

const Manufacturer = () => (
    <Row>
      <Sidebar>
        <Nav.Item>
          <Scrollchor to="" className="nav-link">
            Devices in the Field
          </Scrollchor>
        </Nav.Item>
      </Sidebar>
      <Col sm={10}>
        <Container fluid className="mt-5">
          <TitleRow id="overview" title="Field Overview" />
          <Row>
            <Col>
              <MetricWidget metric={238} explanation="devices in the field" />
            </Col>
            <Col>
              <MetricWidget metric={210} explanation="active devices" />
            </Col>
            <Col>
              <MetricWidget metric={28} explanation="devices in maintenance" />
            </Col>
          </Row>
          <SubscriptionPlanOverview />
          <TitleRow id="" title="Consumables" />
          <Row>
            <Col>
              <MetricWidget
                metric={67}
                explanation="brushes shipped past month"
              />
            </Col>
            <Col>
              <MetricWidget metric={78} explanation="ordered for next month" />
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
);

export default Manufacturer;
