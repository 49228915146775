import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

import './LineChartWidget.css';

const timeStampFormatter = timeStamp => moment(timeStamp).format('H:mm:ss');

const LineChartWidget = ({
  title,
  timeSeries,
  dataKey,
  dataKey2,
  name,
  name2,
}) => (
  <div>
    <h3>{title}</h3>
    <ResponsiveContainer width="95%" height={250}>
      <LineChart
        data={timeSeries}
        syncId="roomba123"
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="_source.timestamp"
          tickFormatter={timeStampFormatter}
          type="number"
          scale="time"
          domain={['auto', 'auto']}
          reversed
          tickCount={10}
          interval={5 * 60}
        />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* <Tooltip content={<CustomTooltip />} /> */}
        <Line
          type="linear"
          dataKey={dataKey}
          name={name}
          stroke="red"
          dot={false}
        />
        {dataKey2 && (
          <Line
            type="linear"
            dataKey={dataKey2}
            stroke="green"
            name={name2}
            dot={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default LineChartWidget;
