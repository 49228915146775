import React, { Fragment } from 'react';

import './MetricWidget.css';

const MetricWidget = ({ metric, explanation }) => (
  <Fragment>
    <div
      className="text-center border rounded p-2 align-self-center"
      style={{ width: '50%', margin: 'auto' }}
    >
      <div style={{}}>
        <h3>{metric}</h3>
        <p className="muted">{explanation}</p>
      </div>
    </div>
  </Fragment>
);

export default MetricWidget;
