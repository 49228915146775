import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import ReactSpeedometer from 'react-d3-speedometer';
import Scrollchor from 'react-scrollchor';
import moment from 'moment';
import { guid } from 'react-agenda';
import Control from './Control';
import Calendar from '../components/Calendar';
import UsagePlans from '../components/UsagePlans';
import Sidebar from '../components/Sidebar';
import MetricWidget from '../components/MetricWidget';
import TitleRow from '../components/TitleRow';
import { fetchMetric } from '../store/actions/dashboardActions';
import { toDistanceInMeter } from '../lib/metricHelpers';


const items = [
  {
    _id: guid(),
    name: 'Morning Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .hours(6)
      .minutes(30)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .hours(7)
      .minutes(30)
      .toDate(),
    classes: 'color-1',
  },
  {
    _id: guid(),
    name: 'Evening Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .hours(19)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .hours(20)
      .minutes(30)
      .toDate(),
    classes: 'color-2',
  },
  {
    _id: guid(),
    name: 'Morning Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .add(1, 'days')
      .hours(6)
      .minutes(30)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(1, 'days')
      .hours(7)
      .minutes(30)
      .toDate(),
    classes: 'color-1',
  },
  {
    _id: guid(),
    name: 'Evening Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .add(1, 'days')
      .hours(19)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(1, 'days')
      .hours(20)
      .minutes(30)
      .toDate(),
    classes: 'color-2',
  },
  {
    _id: guid(),
    name: 'Morning Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .add(2, 'days')
      .hours(6)
      .minutes(30)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(2, 'days')
      .hours(7)
      .minutes(30)
      .toDate(),
    classes: 'color-1',
  },
  {
    _id: guid(),
    name: 'Evening Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .add(2, 'days')
      .hours(19)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(2, 'days')
      .hours(20)
      .minutes(30)
      .toDate(),
    classes: 'color-2',
  },
  {
    _id: guid(),
    name: 'Morning Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .add(3, 'days')
      .hours(6)
      .minutes(30)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(3, 'days')
      .hours(7)
      .minutes(30)
      .toDate(),
    classes: 'color-1',
  },
  {
    _id: guid(),
    name: 'Evening Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .add(3, 'days')
      .hours(19)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(3, 'days')
      .hours(20)
      .minutes(30)
      .toDate(),
    classes: 'color-2',
  },
  {
    _id: guid(),
    name: 'Morning Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .add(4, 'days')
      .hours(6)
      .minutes(30)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(4, 'days')
      .hours(7)
      .minutes(30)
      .toDate(),
    classes: 'color-1',
  },
  {
    _id: guid(),
    name: 'Evening Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .add(4, 'days')
      .hours(19)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(4, 'days')
      .hours(20)
      .minutes(30)
      .toDate(),
    classes: 'color-2',
  },
  {
    _id: guid(),
    name: 'intensive Cycle',
    startDateTime: moment()
      .startOf('isoweek')
      .add(5, 'days')
      .hours(8)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(5, 'days')
      .hours(18)
      .toDate(),
    classes: 'color-3',
  },
];

class ProductOwner extends Component {
  componentDidMount() {
    this.props.fetchMetric('distance', 'sum', 'now/d');
    this.props.fetchMetric('distance', 'count', 'now/d');
  }

  render() {
    const distance = this.props.dashboard.distance_sum;
    const distanceToday = distance ? toDistanceInMeter(distance) : 'loading...';
    const distanceThisWeek = distance ? 8 * toDistanceInMeter(distance) : 'loading...';
    const distanceThisMonth = distance ? 30 * toDistanceInMeter(distance) : 'loading...';
    const minutesActive = Math.round(this.props.dashboard.distance_count / 60) || 'loading...';
    const minutesActiveThisWeek = 7 * Math.round(this.props.dashboard.distance_count / 60) || 'loading...';
    const minutesActiveThisMonth = 30 * Math.round(this.props.dashboard.distance_count / 60) || 'loading...';
    return (
      <Row>
        <Sidebar className="pt-5">
          <Nav.Item>
            <Scrollchor to="" className="nav-link">
              Overview
            </Scrollchor>
          </Nav.Item>
          <Nav.Item>
            <Scrollchor to="#control" className="nav-link">
              Control
            </Scrollchor>
          </Nav.Item>
          <Nav.Item>
            <Scrollchor to="#schedule" className="nav-link">
              Working Schedule
            </Scrollchor>
          </Nav.Item>
          <Nav.Item>
            <Scrollchor to="#plans" className="nav-link">
              Subscription Plan
            </Scrollchor>
          </Nav.Item>
        </Sidebar>
        <Col sm={10}>
          <Container fluid className="mt-5">
            <TitleRow id="overview" title="Today" />
            <Row>
              <Col>
                <MetricWidget metric={minutesActive} explanation="minutes cleaned" />
              </Col>
              <Col>
                <MetricWidget metric={distanceToday} explanation="meters driven" />
              </Col>
              <Col>
                <ReactSpeedometer minValue={0} maxValue={100} value={24} />
              </Col>
            </Row>
            <TitleRow id="" title="This Week" />
            <Row>
              <Col>
                <MetricWidget metric={minutesActiveThisWeek} explanation="minutes cleaned" />
              </Col>
              <Col>
                <MetricWidget metric={distanceThisWeek} explanation="meters driven" />
              </Col>
              <Col>
                <ReactSpeedometer minValue={0} maxValue={100} value={56} />
              </Col>
            </Row>
            <TitleRow id="" title="This Month" />
            <Row>
              <Col>
                <MetricWidget metric={minutesActiveThisMonth} explanation="minutes cleaned" />
              </Col>
              <Col>
                <MetricWidget metric={distanceThisMonth} explanation="meters driven" />
              </Col>
              <Col>
                <ReactSpeedometer minValue={0} maxValue={100} value={48} />
              </Col>
            </Row>
            <Control />
            <TitleRow
              className="mt-3 pt-3"
              id="schedule"
              title="Mark the timeslots you want your roomba to be active."
            />
            <Row>
              <Col>
                <Calendar items={items} />
              </Col>
            </Row>
            <UsagePlans />
          </Container>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
});

const mapDispatchToProps = dispatch => ({
  fetchMetric: (metric, aggregation, since) => dispatch(fetchMetric(metric, aggregation, since)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductOwner);
