// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2019-10-02T12:25:02.994Z

const awsmobile = {
    aws_cloud_logic_custom: [
        {
            endpoint: 'https://91p3zwzs5e.execute-api.eu-west-1.amazonaws.com/dev',
            name: 'ApiGatewayRestApi',
            region: 'eu-west-1'
        }
    ],
    aws_cognito_identity_pool_id: 'eu-west-1:722bb63e-59e6-415f-90b5-3073bdaf982a',
    aws_cognito_region: 'eu-west-1',
    aws_project_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_txuYS5yIb',
    aws_user_pools_web_client_id: '16ese5i7rf49vvrkppdvqt1kk0'
};

export default awsmobile;
