import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const RoombaSelector = () => (
  <Fragment>
    <Form>
      <Form.Group as={Row} controlId="roomba.select" className="d-flex justify-content-center">
        <Form.Label column sm={2}>
          Select Roomba:
        </Form.Label>
        <Col sm={2}>
          <Form.Control as="select">
            <option>Robot-7xt5dw3</option>
            <option>Robot-43xqqer</option>
          </Form.Control>
        </Col>
      </Form.Group>
    </Form>
  </Fragment>
);

export default RoombaSelector;
