import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { SignIn } from 'aws-amplify-react';

class CustomSignInForm extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
  }

  showComponent() {
    const params = new URLSearchParams(window.location.search.substring(1));
    let email = params.get('email');
    let password = params.get('password');
    if (email && password) {
      email = decodeURIComponent(email);
      password = decodeURIComponent(password);
      this.inputs.username = email; // make sure that when the form is prefilled, the rest of the amplify stuff is bootstrapped
      this.inputs.password = password;
    }
    return (
      <Form onSubmit={this.signIn}>
        <Form.Group>
          <Form.Label>Email address</Form.Label>

          <Form.Control
            type="email"
            placeholder="Enter your email"
            id="username"
            key="username"
            name="username"
            value={email}
            onChange={this.handleInputChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            id="password"
            key="password"
            name="password"
            value={password}
            onChange={this.handleInputChange}
            type="password"
            placeholder="Your Password"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
    );
  }
}

export default CustomSignInForm;
