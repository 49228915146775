import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import CountDown from '../components/CountDown';
import RoombaControls from '../components/RoombaControls';
import { postRoombaAction } from '../store/actions/controlActions';
import { fetchMetric } from '../store/actions/dashboardActions';
import MetricWidget from '../components/MetricWidget';
import Chrono from '../components/Chrono';
import { toDistanceInMeter } from '../lib/metricHelpers';

const GAME_NOT_STARTED = 'game-not-started';
const GAME_COUNTING_DOWN = 'game-counting-down';
const GAME_BUSY_PLAYING = 'game-busy';
const GAME_OVER = 'game-over';

class Game extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      gameState: GAME_NOT_STARTED,
      startTime: null,
      time: null,
      player: '',
    };
    this.handleStart = this.handleStart.bind(this);
    this.handleCountDownComplete = this.handleCountDownComplete.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handlePlayAgain = this.handlePlayAgain.bind(this);
    this.fetchStats = this.fetchStats.bind(this);
    this.handlePlayerChange = this.handlePlayerChange.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleStart() {
    this.setState({ gameState: GAME_COUNTING_DOWN });
  }

  handleCountDownComplete() {
    this.setState({ gameState: GAME_BUSY_PLAYING, startTime: moment() });
    this.interval = setInterval(this.fetchStats, 5000);
    console.log(this.interval);
  }

  handleStop(time) {
    console.log(this.interval);
    clearInterval(this.interval);
    this.setState({ time, gameState: GAME_OVER });
  }

  handlePlayAgain() {
    this.setState({ time: null, gameState: GAME_NOT_STARTED });
  }

  fetchStats() {
    const since = moment().diff(this.state.startTime, 'seconds');
    this.props.fetchMetric('distance', 'sum', `now-${since}s`);
    this.props.fetchMetric('lightbump', 'sum', `now-${since}s`);
  }

  handlePlayerChange(event) {
    this.setState({ player: event.target.value });
  }

  showGameExplanation() {
    const { gameState, player } = this.state;
    return (
      gameState === GAME_NOT_STARTED && (
        <Row>
          <Col>
            <Jumbotron>
              <h2>Roomba Game</h2>
              <p>
                Rij de Roomba naar huis! <br />{' '}
                Rij zo snel je kunt, zonder ergens tegenaan te botsen.
                Elke keer je botst, krijg je een tijdstraf van 10 seconden.
              </p>
              <p>
                Vul gauw je naam in en druk op start. Vergeet niet op stop te drukken als de Roomba thuis is. (roomba's zijn niet heel slim). Gebruik de pijltjes om te sturen.
              </p>
              <p className="text-center">
                <Form>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={player}
                      placeholder="Your Name"
                      onChange={this.handlePlayerChange}
                      size="sm"
                    />
                  </Form.Group>

                  <Button
                    variant="success"
                    size="lg"
                    disabled={gameState !== GAME_NOT_STARTED || player === ''}
                    onClick={this.handleStart}
                  >
                    Start
                  </Button>
                </Form>
              </p>
            </Jumbotron>
          </Col>
        </Row>
      )
    );
  }

  showCountDown() {
    const { gameState } = this.state;
    return (
      gameState === GAME_COUNTING_DOWN && (
        <CountDown seconds={3} onComplete={this.handleCountDownComplete} />
      )
    );
  }

  showTheGame() {
    const { gameState } = this.state;
    const rawDistance = this.props.dashboard.distance_sum || 0;
    const distance = toDistanceInMeter(rawDistance);
    const bumps = this.props.dashboard.lightbump_sum || 0;
    const { postAction } = this.props;
    return (
      gameState === GAME_BUSY_PLAYING && (
        <Fragment>
          <Row>
            <Col>
              <MetricWidget metric={distance} explanation="meters" />
            </Col>
            <Col className="justify-content-center">
              <Chrono onStop={this.handleStop} />
            </Col>
            <Col>
              <MetricWidget metric={bumps} explanation="bumps" />
            </Col>
          </Row>
          <Row className="text-center mt-5 justify-content-center">
            <Col lg={8} md={12}>
              <RoombaControls postAction={postAction} />
            </Col>
          </Row>
        </Fragment>
      )
    );
  }

  showGameOver() {
    const { gameState, time } = this.state;
    const timeinSeconds = Math.round(moment.duration(time).asSeconds() * 10) / 10;
    const bumps = this.props.dashboard.lightbump_sum || 0;
    const totalTime = timeinSeconds + bumps;
    return (
      gameState === GAME_OVER && (
        <Fragment>
          <Row className="justify-content-center">
            <Col md={3}>
              <h3>Summary</h3>
              <p>Your Time: {timeinSeconds} seconds</p>
              <p>Penalty for Bumps: {bumps} seconds </p>
              <p>Total Time: {totalTime} seconds </p>
              <p>
                <Button onClick={this.handlePlayAgain}>Play Again</Button>
              </p>
            </Col>
          </Row>
        </Fragment>
      )
    );
  }

  render() {
    return (
      <Container className="mt-5 pt-5 pb-5 game-container">
        {this.showGameExplanation()}
        {this.showCountDown()}
        {this.showTheGame()}
        {this.showGameOver()}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
});

const mapDispatchToProps = dispatch => ({
  postAction: roombaAction => dispatch(postRoombaAction(roombaAction)),
  fetchMetric: (metric, aggregation, since) => dispatch(fetchMetric(metric, aggregation, since)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Game);
