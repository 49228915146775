import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TitleRow = ( {id, title, className }) => (
  <Row id={id} className={className}>
    <Col className="text">
      <h3>{title}</h3>
    </Col>
  </Row>
);

export default TitleRow;
