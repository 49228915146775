import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import './Footer.css';

export default () => (
  <Container fluid className="footer">
    <Row>
      <Col>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col className="px-5">
        <Image src="/images/logo.png" />
      </Col>
      <Col className="text-center">
        <p style={{ marginBottom: 8 }}>Made by Sirris.</p>
      </Col>
      <Col>With support of <Image src="/images/vlaio-logo.png" /></Col>
    </Row>
    <Row className="footer-colors">
      <Col className="darkblue" />
      <Col className="darkred" />
      <Col className="orange" />
      <Col className="yellow" />
    </Row>
  </Container>
);
