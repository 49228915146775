import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from './views/NotFound';
import Login from './views/Login';
import ProductOwner from './views/ProductOwner';
import Maintenance from './views/Maintenance';
import Control from './views/Control';
import Home from './views/Home';
import Dashboard from './views/Dashboard';
import Signup from './views/Signup';
import Manufacturer from './views/Manufacturer';
import Game from './views/Game';

export default () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/product-owner" component={ProductOwner} />
    <Route exact path="/maintenance" component={Maintenance} />
    <Route exact path="/manufacturer" component={Manufacturer} />
    <Route exact path="/game" component={Game} />
    <Route exact path="/control" component={Control} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/signup" component={Signup} />
    <Route component={NotFound} />
  </Switch>
);
