/* eslint-disable react/prop-types */
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';
import { dismissError } from '../store/actions/errorActions';

class Error extends React.Component {
  constructor(props) {
    super(props);
    this.closeHandle = this.closeHandle.bind(this);
  }

  closeHandle() {
    const { error, dismiss } = this.props;
    dismiss(error);
  }

  render() {
    const { error } = this.props;
    return (
      <Alert
        key={error.id}
        variant="danger"
        dismissible
        onClose={this.closeHandle}
      >
        {error.message}
      </Alert>
    );
  }
}

const Errors = ({ errors, dismiss }) => errors.errors.map(error => (
  <Error error={error} dismiss={dismiss} key={error.id} />
));

const mapStateToProps = state => ({
  errors: state.errors,
});

const mapDispatchToProps = dispatch => ({
  dismiss: (error) => {
    dispatch(dismissError(error));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Errors);
