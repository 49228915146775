/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Logger } from 'aws-amplify';
import './Home.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const logger = new Logger('Home');

const Home = ({ auth }) => {
  logger.debug(auth);
  const { currentUser } = auth;
  if (typeof currentUser !== 'undefined' && currentUser) {
    return <Redirect to="/product-owner" />;
  }
  return (
    <Row>
      <Col className="text-center">
        <h1>Sirris Digital Servitization Demonstrator</h1>
        <p>TThis demonstrator, developed in the context of the <a href="https://www.sirris.be/testing-grounds-digital-servitisation" target="_blank" rel="noopener noreferrer">Proeftuin Digital Servitisation</a>, illustrates how to rapidly set up a prototype of a smart, connected product with easy-to-use technological building blocks. This enables companies to complement their physical products with digital services and explore new business model opportunities.
        </p>
        <Link className="btn btn-large btn-primary" to="/login">
          Please login
        </Link>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Home);
