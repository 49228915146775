import React, { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MetricWidget from './MetricWidget';
import TitleRow from './TitleRow';

const MaintenanceOverview = () => (
  <Fragment>
    <TitleRow id="" title="Maintence Statistics" />
    <Row>
      <Col><MetricWidget metric={12} explanation="days since last maintenance" /></Col>
      <Col><MetricWidget metric={3} explanation="hours since last maintenance" /></Col>
    </Row>
    <Row>
      <Col><MetricWidget metric={4} explanation="average obstacle hit/day" /></Col>
      <Col><MetricWidget metric={3.5} explanation="cleaning hours/dag on average" /></Col>
    </Row>
  </Fragment>
);

export default MaintenanceOverview;
