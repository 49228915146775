import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Loading from './Loading';

import { logout } from '../store/actions/authActions';

const Navigation = ({ auth, logoutFn }) => {
  const loginLink = !auth.currentUser ? (
    <Nav.Item>
      <NavLink to="/login" exact>
        Login
      </NavLink>
    </Nav.Item>
  ) : (
    <Button onClick={logoutFn} type="button" variant="outline-primary">
      Logout
    </Button>
  );

  return (
    <Container fluid className="p-0 mb-0">
      <Navbar bg="light" variant="light" expand="md">
        <Navbar.Brand>
          <NavLink to="/">Sirris Digital Servitization Demonstrator</NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {auth.currentUser && (
            <Nav className="mr-auto">
              <Nav.Link>
                <NavLink to="/dashboard">Dashboard</NavLink>
              </Nav.Link>
              <Nav.Link>
                <NavLink to="/product-owner">Product Owner</NavLink>
              </Nav.Link>
              <Nav.Link>
                <NavLink to="/maintenance">Maintenance Technician</NavLink>
              </Nav.Link>
              <Nav.Link>
                <NavLink to="/manufacturer">Product Manufacturer</NavLink>
              </Nav.Link>
              <Nav.Link>
                <NavLink to="/game">Game</NavLink>
              </Nav.Link>
              <Loading />
            </Nav>
          )}
        </Navbar.Collapse>
        {loginLink}
      </Navbar>
    </Container>
  );
};

const mapStateToProps = state => ({ auth: state.auth });

const mapDispatchToProps = dispatch => ({
  logoutFn: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Navigation);
