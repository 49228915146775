import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import QRCode from 'qrcode.react';

const SignupLink = ({ createdUser }) => {
  let url = `${window.location.protocol}//${window.location.hostname}/login`;
  if (createdUser) {
    url += `?email=${encodeURIComponent(createdUser.email)}&password=${encodeURIComponent(createdUser.password)}`;
  }
  url = encodeURI(url);
  return createdUser ? (
    <Row className="justify-content-center">
      <Col xl={6}>
        <h3>User created successfully</h3>
        <p>Please use the link below to login</p>
        <p>
          <a href={url} _target="blank">{url}</a>
        </p>
        <p>
          <QRCode value={url} />
        </p>
      </Col>
    </Row>
  ) : ('');
};

export default SignupLink;
