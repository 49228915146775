import React, { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { fetchDashboardData } from '../store/actions/dashboardActions';
import LineChartWidget from './LineChartWidget';
import TitleRow from './TitleRow';

import './MaintenanceData.css';

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.fetchDashboardData();
    this.interval = setInterval(() => this.props.fetchDashboardData(), 10 * 1000);
  }

  componentWillUnmount() {
  clearInterval(this.interval);
}

  render() {
    const { timeSeries } = this.props.dashboard;
    return (
      <Fragment>
        <TitleRow className="mt-5 pb-2" id="data" title="Robot Statistics" />
        <Row>
          <Col>
            <LineChartWidget
              title="Battery Charge"
              timeSeries={timeSeries}
              dataKey="_source.battery_charge"
            />
          </Col>
          <Col>
            <LineChartWidget
              title="Battery Capacity"
              timeSeries={timeSeries}
              dataKey="_source.battery_capacity"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <LineChartWidget
            title="Left Cliff Signal"
            timeSeries={timeSeries}
            dataKey="_source.cliff_left_signal"
            />
          </Col>
          <Col>
            <LineChartWidget
            title="Right Cliff Signal"
            timeSeries={timeSeries}
            dataKey="_source.cliff_right_signal"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <LineChartWidget
            title="Left Motor Current"
            timeSeries={timeSeries}
            dataKey="_source.left_motor_current"
            />
          </Col>
          <Col>
            <LineChartWidget
            title="Right Motor Current"
            timeSeries={timeSeries}
            dataKey="_source.right_motor_current"
            />
          </Col>
        </Row>

      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
});

const mapDispatchToProps = dispatch => ({
  fetchDashboardData: () => dispatch(fetchDashboardData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
