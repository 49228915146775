import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RoombaControls = ({ title, postAction }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text className="text-center">
          <span className="basic-function-button">
            <Button
              primary
              onClick={() => {
                postAction('forward');
              }}
            >
              <FontAwesomeIcon icon="angle-up" /> Vooruit
            </Button>
          </span>
          <br /> <br />
          <span className="basic-function-button">
            <Button
              primary
              onClick={() => {
                postAction('left');
              }}
            >
              <FontAwesomeIcon icon="angle-left" /> Links
            </Button>
          </span>
          <span className="basic-function-button">
            <Button
              primary
              onClick={() => {
                postAction('right');
              }}
            >
              Rechts <FontAwesomeIcon icon="angle-right" />
            </Button>
          </span>
          <br /> <br />
          <span className="basic-function-button">
            <Button
              primary
              onClick={() => {
                postAction('backwards');
              }}
            >
              <FontAwesomeIcon icon="angle-down" /> Achteruit
            </Button>
          </span>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default RoombaControls;
