import { API } from 'aws-amplify';
import { NEW_ERROR } from './errorActions';

export const LOADING_DATA = 'loading/loadingData';
export const DATA_LOADED = 'loading/dataLoaded';

export const loadingData = () => ({
  type: LOADING_DATA,
});

export const dataLoaded = () => ({
  type: DATA_LOADED,
});

// dispatcher is a function that takes in the data fetched from the server
// and returns an action that is than dispatched
export function get(path, dispatcher, queryStringParameters = {}) {
  return async dispatch => {
    dispatch(loadingData());
    try {
      const myInit = { queryStringParameters };
      console.log(myInit);
      const data = await API.get('roomba', path, myInit);
      dispatch(dispatcher(data));
    } catch (err) {
      dispatch({
        type: NEW_ERROR,
        error: { message: err.message, id: new Date().getTime() },
      });
      dispatch(dispatcher(null));
    } finally {
      dispatch(dataLoaded());
    }
  };
}

export function post(path, postData, dispatcher) {
  return async dispatch => {
    dispatch(loadingData());
    try {
      const myInit = { body: postData };
      const data = await API.post('roomba', path, myInit);
      dispatch(dispatcher(data));
    } catch (err) {
      dispatch({
        type: NEW_ERROR,
        error: { message: err.message, id: new Date().getTime() },
      });
    } finally {
      dispatch(dataLoaded());
    }
  };
}
