import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postRoombaAction } from '../store/actions/controlActions';
import RoombaControls from '../components/RoombaControls';
import TitleRow from '../components/TitleRow';

import './Control.css';

const Control = ({ postAction }) => {
  return (
    <Fragment>
      <TitleRow id="control" title="Control" />
      <Row style={{ marginTop: '20px' }}>
        <Col>
          <CardDeck>
            <Card style={{ marginRight: 0 }}>
              <Card.Body>
                <Card.Title>Basic Functions</Card.Title>
                <Card.Subtitle>Run a basic function</Card.Subtitle>
                <Card.Text>
                  <br />
                  <span className="basic-function-button">
                    <Button
                      onClick={() => {
                        postAction('clean');
                      }}
                    >
                      Clean
                    </Button>
                  </span>
                  <span className="basic-function-button">
                    <Button
                      onClick={() => {
                        postAction('spot');
                      }}
                    >
                      Spot
                    </Button>
                  </span>
                  <span className="basic-function-button">
                    <Button
                      onClick={() => {
                        postAction('dock');
                      }}
                    >
                      Dock
                    </Button>
                  </span>
                  <br />
                  <br />
                  <span className="basic-function-button">
                    <Button
                      variant="danger"
                      onClick={() => {
                        postAction('poweroff');
                      }}
                    >
                      <FontAwesomeIcon icon="power-off" /> Power Off
                    </Button>
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
            <RoombaControls title="Custom Controls" postAction={postAction}/>
          </CardDeck>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  postAction: roombaAction => dispatch(postRoombaAction(roombaAction)),
});

export default connect(
  null,
  mapDispatchToProps,
)(Control);
