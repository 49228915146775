/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactSpeedometer from 'react-d3-speedometer';
import TrafficLight from 'react-trafficlight';
import RoombaSelector from '../components/RoombaSelector';
import { fetchDashboardData } from '../store/actions/dashboardActions';
import LineChartWidget from '../components/LineChartWidget';
import TitleRow from '../components/TitleRow';

const batteryChargeAsPercentage = singleDataPoint => {
  if (singleDataPoint) {
    const { battery_charge } = singleDataPoint._source;
    return Math.round((100 * battery_charge) / 65535);
  }
  return 0;
};

const averageForMetric = (dataPoints, metric) => {
  if (dataPoints.length === 0) {
    return 0;
  }
  return (
    dataPoints.reduce((total, dataPoint) => {
      return total + dataPoint._source[metric] || 0;
    }, 0) / dataPoints.length
  );
};

class Dashboard extends Component {
  componentDidMount() {
    this.props.fetchDashboardData();
    this.interval = setInterval(
      () => this.props.fetchDashboardData(),
      10 * 1000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { timeSeries } = this.props.dashboard;
    const batteryCharge = batteryChargeAsPercentage(timeSeries[0]);
    const dirtDetected = timeSeries[0] && timeSeries[0]._source && timeSeries[0]._source.dirt_detected > 0;
    const avgCliffs = averageForMetric(timeSeries, 'cliff_front_left_signal');
    const aboveAvgCliffs = timeSeries[0] && timeSeries[0]._source && timeSeries[0]._source.cliff_front_left_signal > avgCliffs;
    const avgBumps = averageForMetric(timeSeries, 'lightbump_center_left');
    const aboveAvgBumps = timeSeries[0] && timeSeries[0]._source && timeSeries[0]._source.lightbump_center_left > avgBumps;
    const avgCurrent = averageForMetric(timeSeries, 'left_motor_current');
    const aboveAvgCurrent = timeSeries[0] && timeSeries[0]._source && timeSeries[0]._source.left_motor_current > avgCurrent;
    const statusImg = aboveAvgCurrent ? 'images/overvoltage.png' : 'images/no-warnings.png' ;

    return (
      <Container fluid>
        <RoombaSelector />
        <hr />
        <Row>
          <Col>
            <ReactSpeedometer
              minValue={0}
              maxValue={100}
              value={batteryCharge}
              // eslint-disable-next-line no-template-curly-in-string
              currentValueText="Battery Status: ${value}%"
            />
          </Col>
          <Col>
            <ReactSpeedometer
              minValue={0}
              maxValue={100}
              value={24}
              currentValueText="Hours cleaned: ${value}"
            />
          </Col>
          <Col>
            {' '}
            <ReactSpeedometer
              minValue={0}
              maxValue={1000}
              value={341}
              currentValueText="Time til new brushes: ${value}h"
            />
          </Col>
        </Row>
        <hr />
        <TitleRow
          className="mt-5 pb-2"
          id="data"
          title="Roomba maintenance status"
        />
        <Row>
          <Col md={8}>
            <LineChartWidget
              title="Motor Current"
              timeSeries={timeSeries}
              dataKey="_source.left_motor_current"
              dataKey2="_source.right_motor_current"
              name="Left Motor Current"
              name2="Right Motor Current"
            />
          </Col>
          <Col md={4}>
            <h4>Machine Status</h4>
            <p>
              <img src={statusImg} alt="machine status" />
            </p>
          </Col>
        </Row>
        <hr />
        <Row className="text-center">
          <Col>
            <TrafficLight GreenOn={!dirtDetected} RedOn={dirtDetected} />
            <h5>Dirt</h5>
          </Col>
          <Col>
            <TrafficLight GreenOn={!aboveAvgCliffs} RedOn={aboveAvgCliffs} />
            <h5>Cliffs</h5>
          </Col>
          <Col>
            <TrafficLight GreenOn={!aboveAvgBumps} RedOn={aboveAvgBumps} />
            <h5>Bumps</h5>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
});

const mapDispatchToProps = dispatch => ({
  fetchDashboardData: () => dispatch(fetchDashboardData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
