import { DASHBOARD_DATA_FETCHED, METRIC_FETCHED } from '../actions/dashboardActions';

const initialState = {
  timeSeries: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_DATA_FETCHED:
      return {
        ...state,
        timeSeries: action.timeSeries,
      };
    case METRIC_FETCHED:
      // eslint-disable-next-line no-case-declarations
      const newState = { ...state };
      const { metric, data } = action;
      newState[metric] = data;
      return newState;
    default:
      return state;
  }
};
