import React, { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import TitleRow from './TitleRow';

import './UsagePlans.css';

export default () => (
  <Fragment>
    <TitleRow id="plans" title="Pay per Use Subscription Plan" />
  <Row>
    <Col>
      <p><Button variant="secondary" disabled size="lg">Small<br /></Button> <Button size="lg">Medium<br /><span className="plan-details">5 of 50 schedules used</span></Button> <Button size="lg" variant="secondary" disabled>Large</Button></p>
      <p><Button>Order additional schedules.</Button></p>
      <h3>Consumables As A Service</h3>
      <p>Brushes have been used for <Button variant="outline-primary" disabled>120 hours</Button></p>
      <p>Next delivery of brushes <Button variant="outline-primary" disabled>11 days</Button></p>
    </Col>
  </Row>
  </Fragment>
);
