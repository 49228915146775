import { combineReducers } from 'redux';
import auth from './auth';
import loading from './loading';
import dashboard from './dashboardReducer';
import errors from './errors';


export default combineReducers({
  auth,
  loading,
  dashboard,
  errors,
});
