import React, { Component, Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './CountDown.css';

export default class CountDown extends Component {
  constructor(props) {
    super(props);
    const { seconds } = props;
    this.state = { remaining: seconds };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      let { remaining } = this.state;
      remaining -= 1;
      if (remaining < 0) {
        this.stop();
        return;
      }
      this.setState({ remaining });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  stop() {
    clearInterval(this.interval);
    const { onComplete } = this.props;
    onComplete();
  }

  render() {
    const { remaining } = this.state;
    return (
      <Fragment>
        <Row className="justify-content-center text-center">
          <Col md={6} sm={12}>
            <h3>Klaar...?</h3>
            <p className="countdown">{remaining > 0 ? remaining : 'GO'}</p>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
