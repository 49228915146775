import { LOGGING_IN, LOGGED_OUT, NOT_LOGGED_IN, LOGGING_OUT, LOGGED_IN, USER_CREATED } from '../actions/authActions';

const initialState = {
  loggingIn: false,
  currentUser: null,
  createdUser: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
      };

    case LOGGED_IN:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: true,
        currentUser: action.currentUser,
      };

    case LOGGING_OUT:
      return {
        ...state,
        loggingIn: true,
      };
    case LOGGED_OUT:
      return {
        ...state,
        loggingIn: false,
        currentUser: null,
      };
    case NOT_LOGGED_IN:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: false,
        currentUser: null,
      };
    case USER_CREATED:
      return {
        ...state,
        createdUser: { ...action.user },
      };
    default:
      return state;
  }
};
