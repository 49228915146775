import React from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import './Sidebar.css';

const Sidebar = ({ children }) => (
  <Col sm={2} className="sidebar pt-0">
    <Nav className="flex-column">{children}</Nav>
  </Col>
);

export default Sidebar;
