import React, { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { createUser } from '../store/actions/authActions';
import SignupLink from '../components/SignupLink';

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      optIn: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.createUser(this.state);
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      optIn: false,
    });
  }

  render() {
    return (
      <Fragment>
        <SignupLink createdUser={this.props.createdUser} />
        <Row className="justify-content-center mt-5">
          <Col xl={6}>
            <h2>Sign Up for the Demonstrator</h2>
            <Form className="mt-4 mb-5 pb-5" onSubmit={this.handleSubmit}>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="Enter First Name"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Enter Last Name"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="company">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Company"
                  name="company"
                  value={this.state.company}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  We will never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formOptIn">
                <Form.Check
                  type="checkbox"
                  name="optIn"
                  label="I would like to receive an invitation for the free inspiration workshop on digital servitisation"
                  value={this.state.optIn}
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  By agreeing, you allow Sirris to send you newsletters and
                  announcements for events.
                </Form.Text>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                active={!this.props.loadingData}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  loadingData: state.loading.loadingData,
  createdUser: state.auth.createdUser,
});

const mapDispatchToProps = dispatch => ({
  createUser: newUser => dispatch(createUser(newUser)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignupForm);
