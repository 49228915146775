import React from 'react';
import { connect } from 'react-redux';

const loader = ({ loading }) => {
  if (typeof loading.loadingData !== 'undefined' && loading.loadingData) {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }
  return '';
};

const mapStateToProps = state => ({ loading: state.loading });

export default connect(mapStateToProps)(loader);
