/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { ReactAgenda } from 'react-agenda';
import moment from 'moment';


import './Calendar.css';

const colors = {
  'color-1': 'rgba(102, 195, 131 , 1)',
  'color-2': 'rgba(242, 177, 52, 1)',
  'color-3': 'rgba(235, 85, 59, 1)',
};

const now = new Date();

export default class Agenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      cellHeight: 20,
      locale: 'en-gb',
      rowsPerHour: 2,
      numberOfDays: 7,
      startDate: moment()
        .startOf('isoweek')
        .toDate(),
    };
    // this.handleCellSelection = this.handleCellSelection.bind(this);
    // this.handleItemEdit = this.handleItemEdit.bind(this);
    // this.handleRangeSelection = this.handleRangeSelection.bind(this);
  }

  // handleCellSelection(item) {
  //   console.log('handleCellSelection', item);
  // }

  // handleItemEdit(item) {
  //   console.log('handleItemEdit', item);
  // }

  // handleRangeSelection(item) {
  //   console.log('handleRangeSelection', item);
  // }

  render() {
    return (
      <ReactAgenda
          minDate={moment().subtract(14, 'days').toDate()}
          maxDate={new Date(now.getFullYear(), now.getMonth() + 3)}
          disablePrevButton={false}
          startDate={this.state.startDate}
          cellHeight={this.state.cellHeight}
          locale={this.state.locale}
          items={this.state.items}
          numberOfDays={this.state.numberOfDays}
          rowsPerHour={this.state.rowsPerHour}
          itemColors={colors}
          autoScale={false}
          fixedHeader
          startAtTime={6}
          endAtTime={22}
      />
    );
  }
}
