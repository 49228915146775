import React, { Fragment } from 'react';
import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPowerOff, faAngleUp, faAngleDown, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Routes from './Routes';
import store, { history } from './store';
import config from './config';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Errors from './components/Errors';

import './App.css';

library.add(faPowerOff);
library.add(faAngleUp);
library.add(faAngleDown);
library.add(faAngleLeft);
library.add(faAngleRight);

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'roomba',
        endpoint: `${config.apiGateway.URL}`,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

Amplify.Logger.LOG_LEVEL = 'DEBUG';

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Fragment>
        <Navigation />
        <Container fluid className="min-vh-50">
          <Errors />
          <Routes />
        </Container>
        <Container fluid>
          <Row>
            <Footer />
          </Row>
        </Container>
      </Fragment>
    </ConnectedRouter>
  </Provider>
);

export default App;
