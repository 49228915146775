import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Scrollchor from 'react-scrollchor';
import moment from 'moment';
import { guid } from 'react-agenda';
import MaintenanceData from '../components/MaintenanceData';
import MaintenanceOverview from '../components/MaintenanceOverview';
import Calendar from '../components/Calendar';
import Sidebar from '../components/Sidebar';
import TitleRow from '../components/TitleRow';

const items = [
  {
    _id: guid(),
    name: 'Maintenace Robot-7xt5dw3 ',
    startDateTime: moment()
      .startOf('isoweek')
      .hours(9)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .hours(11)
      .minutes(30)
      .toDate(),
    classes: 'color-1',
  },
  {
    _id: guid(),
    name: 'Instalation Robot-3sccf3',
    startDateTime: moment()
      .startOf('isoweek')
      .hours(13)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .hours(17)
      .toDate(),
    classes: 'color-2',
  },
  {
    _id: guid(),
    name: 'Maintance Robot-3eds4f4',
    startDateTime: moment()
      .startOf('isoweek')
      .add(1, 'days')
      .hours(9)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(1, 'days')
      .hours(11)
      .minutes(30)
      .toDate(),
    classes: 'color-1',
  },
  {
    _id: guid(),
    name: 'Maintenence Robot-2df4xsx',
    startDateTime: moment()
      .startOf('isoweek')
      .add(1, 'days')
      .hours(13)
      .toDate(),
    endDateTime: moment()
      .startOf('isoweek')
      .add(1, 'days')
      .hours(17)
      .minutes(30)
      .toDate(),
    classes: 'color-1',
  },
];

const Maintenance = () => (
    <Row>
      <Sidebar>
        <Nav.Item>
          <Scrollchor to="" className="nav-link">
            Device Selection
          </Scrollchor>
        </Nav.Item>
        <Nav.Item>
          <Scrollchor to="#overview" className="nav-link">
            Overview
          </Scrollchor>
        </Nav.Item>
        <Nav.Item>
          <Scrollchor to="#data" className="nav-link">
            Data
          </Scrollchor>
        </Nav.Item>
        <Nav.Item>
          <Scrollchor to="#schedule" className="nav-link">
            Working Schedule
          </Scrollchor>
        </Nav.Item>
      </Sidebar>
      <Col sm={10}>
        <Container fluid className="mt-5">
          <TitleRow id="overview" title="Select Your Roomba" />
          <Form>
            <Form.Row>
              <Form.Group as={Col} sm={12} md={6} controlId="roomba.select">
                <Form.Label>Select your Roomba</Form.Label>
                <Form.Control as="select">
                  <option>Robot-7xt5dw3</option>
                  <option>Robot-43xqqer</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </Form>
          <MaintenanceOverview />
          <MaintenanceData />
          <TitleRow className="my-5 pt-5" id="schedule" title="Maintence Schedule" />
          <Row>
            <Col>
              <Calendar items={items} />
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
);

export default Maintenance;
