import { Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import { post } from './loadingActions';

export const LOGGING_IN = 'auth/LOGGING_IN';
export const LOGGED_IN = 'auth/LOGGED_IN';
export const NOT_LOGGED_IN = 'auth/NOT_LOGGED_IN';
export const LOGGING_OUT = 'auth/LOGGING_OUT';
export const LOGGED_OUT = 'auth/LOGGED_OUT';
export const USER_CREATED = 'auth/USER_CREATED';

export const loggingIn = () => (dispatch) => {
  dispatch({
    type: LOGGING_IN,
  });
};

export const loggingOut = () => (dispatch) => {
  dispatch({
    type: LOGGING_OUT,
  });
};

export function loggedIn() {
  return async (dispatch) => {
    const currentUser = await Auth.currentAuthenticatedUser();
    dispatch({
      type: LOGGED_IN,
      currentUser,
    });
    dispatch(push('/product-owner'));
  };
}

export function initialLogin() {
  return async (dispatch) => {
    dispatch(loggingIn());
    try {
      const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      dispatch({
        type: LOGGED_IN,
        currentUser,
      });
    } catch (err) {
      dispatch({ type: NOT_LOGGED_IN });
    }
  };
}

export function logout() {
  return async (dispatch) => {
    try {
      await Auth.signOut();
      dispatch({
        type: LOGGED_OUT,
      });
      dispatch(push('/'));
    } catch (err) {
      dispatch({ type: LOGGED_OUT });
    }
  };
}

export function createUser(newUser) {
  return post('/user/create', newUser, (user) => ({ type: USER_CREATED, user }));
}
