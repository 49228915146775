import React, { Component, Fragment } from 'react';
import Button from 'react-bootstrap/Button';

class Chrono extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTS: null,
      diff: null,
      suspended: 0,
      interval: null,
    };
    this.tick = this.tick.bind(this);
    this.stop = this.stop.bind(this);
  }

  componentDidMount() {
    this.start();
  }

  start() {
    if (this.state.startTS) {
      // prevent multi clicks on start
      return;
    }
    this.setState({
      startTS: +new Date() - this.state.suspended,
      interval: requestAnimationFrame(this.tick),
      suspended: 0,
    });
  }

  stop() {
    cancelAnimationFrame(this.state.interval);
    this.setState({
      startTS: null,
      suspended: +this.state.diff,
    });
    const { diff } = this.state;
    const millies = diff ? diff.getMilliseconds() : 0;
    const seconds = diff ? diff.getSeconds() : 0;
    const minutes = diff ? diff.getMinutes() : 0;
    this.props.onStop((60 * minutes + seconds) * 1000 + millies);
  }

  reset() {
    cancelAnimationFrame(this.state.interval);
    this.setState(this.getInitialState());
  }

  tick() {
    this.setState({
      diff: new Date(+new Date() - this.state.startTS),
      interval: requestAnimationFrame(this.tick),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  addZero(n) {
    return n < 10 ? `0${n}` : n;
  }

  render() {
    const { diff } = this.state;
    let hundredths = diff ? Math.round(diff.getMilliseconds() / 10) : 0;
    const seconds = diff ? diff.getSeconds() : 0;
    const minutes = diff ? diff.getMinutes() : 0;

    if (hundredths === 100) hundredths = 0;

    return (
      <Fragment>
        <h3>
          {this.addZero(minutes)}:{this.addZero(seconds)}:
          {this.addZero(hundredths)}
        </h3>
        <Button succes onClick={this.stop}>
          STOP
        </Button>
      </Fragment>
    );
  }
}

export default Chrono;
