import { get } from './loadingActions';

export const FETCH_DASHBOARD_DATA = 'dashbaord/fetchDashboardData';
export const DASHBOARD_DATA_FETCHED = 'dashboard/dataFetched';
export const FETCH_METRIC = 'dashboard/fetchMetric';
export const METRIC_FETCHED = 'dashboard/metricFetched';

export function fetchDashboardData() {
  return get('/data', data => ({ type: DASHBOARD_DATA_FETCHED, timeSeries: data || [] }));
}

export function fetchMetric(metric, aggregation, since) {
  const metricName = `${metric}_${aggregation}`;
  const queryStringParameters = { metric, aggregation, since };
  return get('/metric', data => ({ type: METRIC_FETCHED, data, metric: metricName }), queryStringParameters);
}
